var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col wrapper_for_printing"},[_c('div',{staticClass:"while_printing_the_report",attrs:{"id":"while_printing_the_report"}},[_c('div',{staticClass:"information"},[_c('div',{staticClass:"part_one"},[_c('div',{staticClass:"parts"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.name"))+" :")]),_c('span',{staticClass:"when_name"},[_vm._v(_vm._s(_vm.userDataForPrintTwo.name))])]),_c('div',{staticClass:"parts"},[_c('span',[_vm._v(_vm._s(_vm.$t("gender"))+" :")]),_c('span',{staticClass:"when_name"},[_vm._v(" "+_vm._s(_vm.userDataForPrintTwo.gender == 1 ? _vm.$t("admin_dashboard_type.male") : _vm.$t("admin_dashboard_type.female")))])]),_c('div',{staticClass:"parts"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.age"))+":")]),_c('span',{staticClass:"when_name"},[_vm._v(_vm._s(_vm.userDataForPrintTwo.age))])]),_c('div',{staticClass:"parts"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.class_name"))+":")]),_c('span',{staticClass:"when_name"},[_vm._v(_vm._s(_vm.userDataForPrintTwo.group_name))])]),_c('div',{staticClass:"parts spans"},[(
              _vm.$route.params.exam_type == 5 || _vm.$route.params.exam_type == 6
            )?_c('div',{staticClass:"is_span"},[(_vm.$route.params.exam_type == 5)?_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.pre"))+" 1")]):_vm._e(),(_vm.$route.params.exam_type == 6)?_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.pre"))+" 2")]):_vm._e(),_c('span',{staticClass:"border_span",class:{
                pre:
                  _vm.$route.params.exam_type == 5 ||
                  _vm.$route.params.exam_type == 6,
              }})]):_vm._e(),(
              _vm.$route.params.exam_type == 7 || _vm.$route.params.exam_type == 8
            )?_c('div',{staticClass:"is_span ml-4"},[(_vm.$route.params.exam_type == 7)?_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.post"))+" 1")]):_vm._e(),(_vm.$route.params.exam_type == 8)?_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.post"))+" 2")]):_vm._e(),_c('span',{staticClass:"border_span",class:{
                post:
                  _vm.$route.params.exam_type == 7 ||
                  _vm.$route.params.exam_type == 8,
              }})]):_vm._e()]),_c('div',{staticClass:"parts"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.Total time"))+":")]),_c('span',{staticClass:"when_name"},[_vm._v(_vm._s(_vm.calculateMinAndSec(_vm.userDataForPrintTwo.total_time))+" ")])]),_c('div',{staticClass:"parts"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin_dashboard_type.Total inputs/time"))+":")]),_c('span',{staticClass:"when_name"},[_vm._v(_vm._s(_vm.userDataForPrintTwo.time_count))])])]),_vm._m(0)]),_c('div',{staticClass:"is_old_wrapper"},[_c('div',{staticClass:"main_images_checks_container with_english_direction",class:{ with_english_direction: _vm.getLocale == 'en' }},_vm._l((_vm.printPdfReportData.answers),function(item,index){return _c('div',{key:index,staticClass:"wrapper_of_images"},[_c('div',{staticClass:"main_main"},[_c('div',{staticClass:"wrapper_two"},_vm._l((item.object),function(img,i){return _c('img',{key:i,staticClass:"item",class:("custom_img_en_class_" + (i + 1)),style:({ transform: ("rotate(" + (-img.rotation) + "deg)") }),attrs:{"src":img.img_path,"alt":"draw image"}})}),0),_c('div',{staticClass:"text_images"},[_c('span',[_vm._v("("+_vm._s(index + 1)+")")]),_c('span',[_vm._v(_vm._s(item.answer))])])]),_c('div',{staticClass:"is_items_with_checkbox"},_vm._l((_vm.checkboxData),function(it,i){return _c('div',{key:i,staticClass:"is_is_checker"},[_c('span',[_vm._v(_vm._s(it))]),_c('el-checkbox')],1)}),0)])}),0),_vm._m(1)])]),_c('div',{staticClass:"sharing_wrapper for_printing_buttons",on:{"click":_vm.handlerPrint}},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("admin_dashboard_type.print_report")))]),_c('i',{staticClass:"fa fa-share-alt print_now"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"part_two"},[_c('div',{staticClass:"every"},[_c('span',{staticClass:"is_name"},[_vm._v("DAT SCORE")]),_c('input',{staticClass:"item_border date",attrs:{"type":"text"}})]),_c('div',{staticClass:"every"},[_c('span',{staticClass:"is_name"},[_vm._v("Original")]),_c('input',{staticClass:"item_border",attrs:{"type":"text"}})]),_c('div',{staticClass:"every"},[_c('span',{staticClass:"is_name"},[_vm._v("Flex")]),_c('input',{staticClass:"item_border",attrs:{"type":"text"}})]),_c('div',{staticClass:"every"},[_c('span',{staticClass:"is_name"},[_vm._v("Flue")]),_c('input',{staticClass:"item_border",attrs:{"type":"text"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"second_elements"},[_c('div',{staticClass:"all_marks"},[_c('div',{staticClass:"every_mark"},[_c('span',{staticClass:"box_title d-block"},[_vm._v("Total Evaluation")]),_c('input',{staticClass:"box_border d-block",attrs:{"type":"text"}})]),_c('div',{staticClass:"every_mark"},[_c('span',{staticClass:"box_title d-block"},[_vm._v("Original")]),_c('input',{staticClass:"box_border d-block",attrs:{"type":"text"}})]),_c('div',{staticClass:"every_mark"},[_c('span',{staticClass:"box_title d-block"},[_vm._v("Flex")]),_c('input',{staticClass:"box_border d-block",attrs:{"type":"text"}})]),_c('div',{staticClass:"every_mark"},[_c('span',{staticClass:"box_title d-block"},[_vm._v("Flue")]),_c('input',{staticClass:"box_border d-block",attrs:{"type":"text"}})]),_c('div',{staticClass:"every_mark"},[_c('span',{staticClass:"box_title d-block"},[_vm._v("Elaboration")]),_c('input',{staticClass:"box_border d-block",attrs:{"type":"text"}})])])])}]

export { render, staticRenderFns }